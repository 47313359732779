<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <div class="page-wrapper login">
      <div class="page-inner bg-brand-gradient">
        <div class="page-content-wrapper bg-transparent m-0">
          <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
            <div class="d-flex align-items-center container p-0">
              <div class="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9">
                <a href="javascript:void(0)"
                   class="page-logo-link press-scale-down d-flex align-items-center">
                  <img :src="publicPath + 'media/birthday/logo_birthday_2023.png'" class="logo-edutalk" alt="Edutalk Tư Vấn"
                       aria-roledescription="logo">
                  <span class="page-logo-text mr-1"></span>
                </a>
              </div>
            </div>
          </div>
          <div class="flex-1" style="background: url(media/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
            <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
              <div class="row">
                <div class="col-xl-12">
                  <h2 class="fs-xxl fw-500 mb-5 text-white text-center">
                    Lấy lại mật khẩu
                  </h2>
                </div>
                <div class="col-xl-6 ml-auto mr-auto">
                  <div class="card p-4 rounded-plus bg-faded">
                    <form id="js-login" class="form" @submit.prevent="handleSubmit(onSubmit)" autocomplete="off">
                      <div class="form-group">
                        <label class="form-label" for="password">Mật khẩu mới</label>
                        <ValidationProvider vid="password" name="Mật khẩu mới" rules="required|min:6" v-slot="{ errors, classes }">
                          <input type="password" ref="password" name="email" id="password"
                                 :class="classes" class="form-control"
                                 placeholder="Mật khẩu mới - tối thiểu 6 kí tự"
                                 v-model=form.password>
                          <div class="fv-plugins-message-container">
                            <div data-field="name" data-validator="notEmpty"
                                 class="fv-help-block">{{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="form-group">
                        <label class="form-label" for="password_confirmation">Xác nhận mật khẩu</label>
                        <ValidationProvider name="Xác nhận mật khẩu" rules="required|min:6|confirmed:password"
                                            v-slot="{ errors, classes }">
                          <input id="password_confirmation" type="password" ref="password_confirmation" name="password_confirmation" class="form-control"
                                 :class="classes"
                                 placeholder="Xác nhận mật khẩu"
                                 v-model=form.password_confirmation>
                          <div class="fv-plugins-message-container">
                            <div data-field="name" data-validator="notEmpty"
                                 class="fv-help-block">{{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="row no-gutters">
                        <div class="col-md-4 ml-auto text-right">
                          <button id="js-login-btn" class="btn btn-block btn-success btn-lg mt-3 waves-effect waves-themed">
                            <i v-if="loading" class="el-icon-loading"></i>
                            Đổi mật khẩu
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
                2021 © Edutalk by&nbsp;<a href="https://edutalk.edu.vn"
                                          class="text-white opacity-40 fw-500" title="edutalk.edu.vn"
                                          target="_blank">edutalk.edu.vn</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import {RESET_PASSWORD} from "../../../core/services/store/auth.module";

export default {
  name: "ResetPassword",
  data () {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      form: {
        password: "",
        password_confirmation: "",
        email: "",
        token: "",
      },
      loading: false,
    }
  },
  created()  {
    this.form.token = this.$route.query.token;
    this.form.email = this.$route.query.email;
  },
  methods : {
    onSubmit : function (){
      this.loading = true;
      this.$store.dispatch(RESET_PASSWORD, this.form)
      .then((res) => {
        if (!res.error){
          this.$router.push('login').then(()=> {
            this.$message({
              type: 'success',
              message: res.message,
              showClose: true
            });
          });
        }
      })
      .catch((res) => {
        if (res.data?.data?.message_validate_form){
          this.$refs.form.setErrors(res.data.data.message_validate_form);
        }
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>